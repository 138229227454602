import React from 'react';
import _ from 'lodash';
import moment from 'moment-strftime';
import styled from 'styled-components';

import {Layout} from '../components/index';
import {htmlToReact} from '../utils';
import PodcastSubscribe from '../components/PodcastSubscribe'
import Player from '../components/Player'

const EpisodeHeader = styled.header`
  margin-bottom: 40px;

  h1 {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 5px;
  }

  time {
    display: block;
    margin-bottom: 40px;
  }
`;

const NotesWrapper = styled.div`
  a {
    text-decoration: underline;
  }
`

export default class Post extends React.Component {
    render() {
      const fm = this.props.pageContext.frontmatter;
      let player = fm.embedType;
      let embedId = fm.embedId;

      return (
        <Layout {...this.props}>
        <div className="outer">
          <div className="inner-medium">
            <article>
              <EpisodeHeader>
                <h1>{_.get(this.props, 'pageContext.frontmatter.title')}</h1>
                <time
                  dateTime={moment(_.get(this.props, 'pageContext.frontmatter.date')).strftime('%Y-%m-%d %H:%M')}>{moment(_.get(this.props, 'pageContext.frontmatter.date')).strftime('%A, %B %e, %Y')}</time>
                <Player player={player} url={embedId}/>
                <PodcastSubscribe justify="start"/>
              </EpisodeHeader>
              <NotesWrapper>
                {htmlToReact(_.get(this.props, 'pageContext.html'))}
              </NotesWrapper>
            </article>
          </div>
        </div>
        </Layout>
      );
    }
}
